import React from "react";
import { graphql } from "gatsby";

import Seo from "../components/Seo";
import Layouts from "../components/Layouts";
import { PageHeader } from "../components/Atomics/PageHeader";
import { Cta } from "../components/Cta";
import { CareersPreview } from "../components/Screens/Careers/CareersPreview";
import { ImagesGallery } from "../components/Screens/Careers/ImagesGallery";
import { CareerValues } from "../components/Screens/Careers/ValuesCards";

const CareersPage = ({ data }) => {
	const careers = data.allWpCareer.edges || [];
	const page = data.page;
	return (
		<>
			<Seo title="Careers" />
			<Layouts lang={"en"}>
				<PageHeader
					miniHeader="Careers"
					header="Kashat Culture"
					text="We are an ambitious, mobile-first fintech with the mission to solve some of the region’s challenging issues, and become the most progressive and comprehensive digital financial platform."
				/>
				<ImagesGallery imagesGallery={page.imagesSliderCareers.sliderCareers} />
				<CareerValues
					valuesCards={page.values.valuesCareers.values}
					miniHeader={page.values.valuesCareers.title}
					header={page.values.valuesCareers.text}
				/>
				<CareersPreview
					header="Open Vacancies"
					miniHeader={
						<div className="w-5/12">
							If you don’t find one that suits your qualifications, send us your
							CV anyway for future considerations! Email us at{" "}
							<a className=" underline" href="mailto:careers@kashat.com.eg">
								careers@kashat.com.eg
							</a>
						</div>
					}
					lang="en"
					jobsCards={careers}
				/>
				<Cta
					miniHeader="EGYPT's First Nano Financial Service Provider"
					header="Powering Access to Digital Financial Possibilities"
					subHeader="Kashat serves as the entry point to financial inclusion for the un- and underbanked population in Egypt by offering instant, small, short-term, productive loans."
				/>
			</Layouts>
		</>
	);
};
export default CareersPage;
export const CareerEnglishQuery = graphql`
	query CareersEnglish {
		allWpCareer(
			filter: { language: { code: { eq: EN }, slug: { ne: null } } }
		) {
			edges {
				node {
					id
					language {
						code
						locale
					}
					date
					title
					slug
					careerPage {
						shortDescription
						location
					}
				}
			}
		}
		page: wpPage(databaseId: { eq: 270 }) {
			title
			imagesSliderCareers {
				miniHeader
				header
				text
				sliderCareers {
					imageCareersSlider {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1024, placeholder: BLURRED)
							}
						}
					}
					altCareersImageSlider
				}
			}
			values: careersValues {
				valuesCareers {
					title
					text
					values {
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 100, placeholder: BLURRED)
								}
							}
						}
						title
						text
					}
				}
			}
		}
	}
`;
